import { hide as hideOne, display } from 'lambda-dom';
import { values } from 'ramda';
import { HeaderDisplay, HeaderTheme } from './site-header.types';
import { desktopHeader, desktopHeaderLogo, desktopHeaderShareInfo } from '../bootstrap/site-header.bootstrap';
import { mobileHeader, mobileHeaderLogo, mobileHeaderShareInfo } from '../bootstrap/mobile-header.bootstrap';

// ------------------------------------------------------------------------------
//      Private: Helpers
// ------------------------------------------------------------------------------

const show = (elements: HTMLElement[], displayValue?: string) => elements.forEach(display(displayValue || 'block'));
const hide = (elements: HTMLElement[]) => elements.forEach(hideOne);

// ------------------------------------------------------------------------------
//     Private:  State
// ------------------------------------------------------------------------------

let currentTheme: HeaderTheme;
let currentDisplayMode: HeaderDisplay;

// ------------------------------------------------------------------------------
//      Library
// ------------------------------------------------------------------------------

/**
 * Get the name of the currently active theme.
 */
export const getHeaderTheme = () => currentTheme;

/**
 * Get the name of the currently active display mode.
 */
export const getDisplayMode = () => currentDisplayMode;

/**
 * Set the color scheme for both desktop and mobile headers & navigation to the
 * given theme object; one of the set of predefined themes.
 */
export const setHeaderTheme = (theme: HeaderTheme): void => {

    const allThemes = values(HeaderTheme);

    desktopHeader.classList.remove(...allThemes);
    desktopHeader.classList.add(theme);
    mobileHeader.classList.remove(...allThemes);
    mobileHeader.classList.add(theme);

    currentTheme = theme;
};

/**
 * Toggles the header-display mode, activating the mode by given name. Allows to
 * dynamically switch between the Company logo and slogan, the share-value update,
 * or neither.
 */
export const setHeaderDisplay = (newDisplay: HeaderDisplay): HeaderDisplay => {
    switch (newDisplay) {
        case 'brand-logo':
            show([mobileHeaderLogo]);
            show([desktopHeaderLogo], 'flex');
            hide([desktopHeaderShareInfo, mobileHeaderShareInfo]);
            break;

        case 'share-info':
            hide([desktopHeaderLogo, mobileHeaderLogo]);
            show([desktopHeaderShareInfo, mobileHeaderShareInfo], 'flex');
            break;

        case 'none':
            hide([
                desktopHeaderLogo,
                mobileHeaderLogo,
                desktopHeaderShareInfo,
                mobileHeaderShareInfo,
            ]);
            break;

        default:
            return currentDisplayMode;
    }

    currentDisplayMode = newDisplay;
    return currentDisplayMode;
};
