const tag = document.createElement('script');
tag.id = 'youtube-api-script';
tag.src = 'https://www.youtube.com/iframe_api';
const firstScriptTag = document.getElementsByTagName('script')[0]!;

firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag);

export const youtubeApiP = new Promise<void>((resolve) => {
    Object.defineProperty(window, 'onYouTubeIframeAPIReady', { value: resolve });
});
