import { BehaviorSubject, from, NEVER, Observable, partition } from 'rxjs';
import { isNil } from 'ramda';
import { catchError, pluck, switchMap } from 'rxjs/operators';
import { ScrollBlockers } from '../../lib/scrolling.lib';
import { loadImages } from '../../services/image-containers/image-containers.index';
import { addClasses, deferFrames, removeClasses } from 'lambda-dom';
import { delay } from '../../lib/timing.lib';
import { axios } from '../../bootstrap/axios.bootstrap';
import { bugsnagClient } from '../../bootstrap/bugsnag.bootstrap';

export function articlePreviewManager(previewContainer: HTMLElement, previewContentContainer: HTMLElement) {
    // ------------------------------------------------------------------------------
    //      Streams
    // ------------------------------------------------------------------------------
    const previewIdSubject = new BehaviorSubject<number | null>(null);

    const [clears$, loads$] = partition(previewIdSubject, isNil);

    // ------------------------------------------------------------------------------
    //      Lib
    // ------------------------------------------------------------------------------

    const getPreview = (id: number): Observable<string> => {
        const responseP = axios.get<string>(`/article-preview/${id}`);
        return from(responseP).pipe(
            pluck('data'),
            catchError((e) => {
                bugsnagClient.notify(e);
                return NEVER;
            }),
        );
    };

    // ------------------------------------------------------------------------------
    //      Runtime
    // ------------------------------------------------------------------------------

    loads$.pipe(switchMap(getPreview)).subscribe((html) => {
        ScrollBlockers.add('article-preview');
        previewContentContainer.innerHTML = html;
        loadImages(previewContentContainer);
        deferFrames(1, () => addClasses('active')(previewContainer));
    });

    clears$.subscribe(async () => {
        removeClasses('active')(previewContainer);
        ScrollBlockers.remove('article-preview');
        await delay(500);
        if (previewIdSubject.getValue() === null) {
            previewContentContainer.innerHTML = '';
        }
    });

    return {
        /**
         * Loads the preview overlay for the article with given ID.
         */
        loadPreview: async (id: number) => previewIdSubject.next(id),

        /**
         * Clears and hides the article preview overlay.
         */
        clearPreview: () => previewIdSubject.next(null),

        previewId$: previewIdSubject.asObservable(),
    };
}

