import $ from 'jquery';
import { queryAll } from 'lambda-dom';
import { includes } from 'ramda';
import { AjaxFormComponent } from '../../components/AjaxForm/ajax-form.component';
import { createConfig as _createConfig } from '../../components/AjaxForm/ajax-form.config';
import { recaptchaReady } from '../../bootstrap/recaptcha.bootstrap';
import 'bootstrap';
import { closestWithin } from '../../lib/dom.lib';

/**
 * The list of values for `[data-ajax-form]` attributes of all ajax-forms that are inside a
 * "bootstrap modal" context.
 */
const modalForms = [
    'simple-message',
    'personal-message',
    'extended-message',
    'investors-contact-form',
    'brandbook-request-form',
    'vacancy-application-form',
    'career-interest-form',
];

// ------------------------------------------------------------------------------
//      Public API
// ------------------------------------------------------------------------------

/**
 * Auto search the document for conventional modal ajax-form markup, and instantiate a component
 * for each found form. Returns the list of created AjaxFormComponent instances.
 */
export const autoSearch = () => findForms(document)
    .filter((x) => includes(x.dataset.ajaxForm, modalForms))
    .map(createAjaxForm);

// ------------------------------------------------------------------------------
//      Internals
// ------------------------------------------------------------------------------

/**
 * Find all ajax-form elements within given scope. Returns the list of found elements.
 */
const findForms = (scope: HTMLDocument | HTMLElement): HTMLFormElement[] => queryAll('form[data-ajax-form]', scope);

/**
 * Create an AjaxFormComponent instance for given form element, configured for the "bootstrap modal" context.
 */
const createAjaxForm = (form: HTMLFormElement) => new AjaxFormComponent(form, createConfig(form));

/**
 * Creates a valid recaptcha action name for the given form.
 */
const recaptchaAction = (form: HTMLFormElement) => form.dataset.ajaxForm!.replace(/[^A-Za-z_]/g, '_');

/**
 * Create a configuration object for given form, setup for the "bootstrap modal" context.
 */
const createConfig = (form: HTMLFormElement) => _createConfig(form.action, 'POST', {

    async prepareSubmit(formData) {
        const recaptcha = await recaptchaReady;
        const token = await recaptcha.execute(recaptchaAction(form));

        formData.append('g-recaptcha-response', token);
        return formData;
    },

    handleSuccess: () => {
        const wrapper = closestWithin(form, '.ajax-form--wrapper', document.body);
        const modal = closestWithin(form, '.modal', document.body);

        if (wrapper) {
            wrapper.classList.add('success');
        }

        if (wrapper && modal) {
            $(modal).one('hidden.bs.modal', () => wrapper.classList.remove('success'));
        }
    },
});
