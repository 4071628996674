import { DatasetElement } from '../../types/common.types';

export type TranslatedElement = DatasetElement<{ speakupTranslations: string }>;
export type TranslatedPlaceholder = DatasetElement<{ placeholderTranslations: string }, HTMLInputElement>;
export type LocalizedLink = DatasetElement<{ localizedLink: ''; url: string }, HTMLAnchorElement>;
export type TranslateToggle = DatasetElement<{ speakupLocalize: string }>;
export type StepLinkElement = DatasetElement<{ speakupTarget: string }>;
export type ModalLinkElement = DatasetElement<{ speakupModalTarget: string }>;
export type StepElement = DatasetElement<{ speakupStep: string }>;
export type ModalElement = DatasetElement<{ speakupModal: string }>;

export enum SpeakUpSection {
    Intro = 'intro',
    Questionnaire = 'questionnaire',
    Form = 'form',
    Phone = 'phone',
}
