import { search } from './news-subscription-form.lib';

// ------------------------------------------------------------------------------
//      News subscription XHR form (site footers)
// ------------------------------------------------------------------------------
//
//      NULL-safe, so this script may be run when the elements don't actually
//      exist. This allows for inclusion in generic scripts that serve pages
//      without a footer form.
//
export const newsSubscriptionForm = search('#news-subscription-form');
