import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import Cookies, { CookiesStatic } from 'js-cookie';
import $ from 'jquery';
import moment from 'moment';

// ------------------------------------------------------------------------------
//      Bootstrap framework
// ------------------------------------------------------------------------------
//      Required for the modal implementation... :(
import 'bootstrap';

// ------------------------------------------------------------------------------
//      Lib
// ------------------------------------------------------------------------------

const setCookie = () => Cookies.set(cookieKey, cookieVal, { expires: moment().add(5, 'm').toDate() });
const hasCookie = () => Cookies.get(cookieKey) === cookieVal;

// @ts-ignore
const showModal = () => $modal.modal('show');

const IEVersion = () => {
    const ua = window.navigator.userAgent;

    if (ua.indexOf('Trident/7.0') > -1) return 11;
    if (ua.indexOf('Trident/6.0') > -1) return 10;
    if (ua.indexOf('Trident/5.0') > -1) return 9;
    return 0; // not IE9, 10 or 11
};

const isIE = () => IEVersion() !== 0;

// ------------------------------------------------------------------------------
//      Runtime
// ------------------------------------------------------------------------------

const cookieKey = 'browser-support-warned';
const cookieVal = 'TRUE';
const $modal = $('#browser-support-modal');

$modal.on('hidden.bs.modal', setCookie);

fromEvent(document, 'DOMContentLoaded').pipe(
    filter(() => isIE() && ! hasCookie()),
)
    .subscribe(showModal);

// ------------------------------------------------------------------------------
//      debug
// ------------------------------------------------------------------------------

declare global {
    interface Window {
        showBrowserSupportModal(): void;
        Cookies: CookiesStatic;
    }
}
window.showBrowserSupportModal = showModal;
window.Cookies = Cookies;
