import { fromEvent } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

export const scrolls$ = fromEvent(window, 'scroll');

/**
 * Stream emitting the current page offset on scroll changes. This serves as source stream for page updates
 * that are dependent on scroll position / section intersection ratios with the window viewport.
 */
export const pageOffsets$ = scrolls$.pipe(map(() => window.pageYOffset || document.documentElement.scrollTop));
