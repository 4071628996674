export function inputName(namespace: string, asArray: boolean = false): string {

    const [firstSegment, ...otherSegments] = namespace.split('.');

    const otherSegmentsString = otherSegments
        .map((segment) => `[${segment}]`)
        .join('');

    return firstSegment + otherSegmentsString + (asArray ? '[]' : '');
}
