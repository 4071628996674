import { hide, show, touchElement } from 'lambda-dom';
import { any, fromPairs, isNil } from 'ramda';
import { IAjaxForm } from '../../components/AjaxForm/ajax-form.types';
import { AjaxForm } from '../../components/AjaxForm/ajax-form.index';
import { createConfig } from '../../components/AjaxForm/ajax-form.config';
import { recaptchaReady } from '../../bootstrap/recaptcha.bootstrap';

export const search = (selector: string) => touchElement<HTMLFormElement, IAjaxForm | null>(selector, (formElement) => {

    const selNS = (name: string) => `.nsf--${name}`;
    const query = <T extends Element>(name: string) => formElement.querySelector<T>(selNS(name));

    // We expect these elements to exist in the formElement!
    const formContent = query<HTMLElement>('content')!;
    const successContainer = query<HTMLElement>('success')!;
    const successContent = query<HTMLElement>('success-content')!;
    const returnButton = query<HTMLButtonElement>('return')!;

    if (any(isNil, [formContent, successContainer, successContent, returnButton])) {

        console.error('ERROR (vacancy subscription form): Some elements not found:\n', fromPairs([
            [selNS('content'), formContent],
            [selNS('success'), successContainer],
            [selNS('success-content'), successContent],
            [selNS('return'), returnButton],
        ]));

        return null;
    }

    returnButton.addEventListener('click', () => {
        successContent.innerText = '';
        formContent.style.visibility = '';
        hide(successContainer);
    });

    return AjaxForm.create(formElement, createConfig(formElement.action, 'POST', {

        async prepareSubmit(formData) {
            const recaptcha = await recaptchaReady;
            const token = await recaptcha.execute('vacancy_subscription');

            formData.append('g-recaptcha-response', token);

            return formData;
        },

        handleSuccess({ message }) {
            successContent.innerText = message ?? 'Thank you for subscribing!';
            formContent.style.visibility = 'hidden';
            show(successContainer);
        },
    }));
});
