import bugsnag from '@bugsnag/js';
import { defaultTo } from 'ramda';
import { getMeta } from 'lambda-dom';
import { Environment } from '../types/common.types';

export const bugsnagClient = bugsnag.createClient({
    apiKey: defaultTo('', getMeta('bugsnag-notifier-key')),
    releaseStage: defaultTo(Environment.UNKNOWN, getMeta('environment')),
    enabledReleaseStages: [
        Environment.ACCEPTANCE,
        Environment.PRODUCTION,
    ],
});
