export enum Environment {
    DEVELOPMENT = 'local',
    ACCEPTANCE = 'acceptance',
    PRODUCTION = 'production',
    UNKNOWN = 'client-env-unknown',
}

/**
 * Type decorator for an element `T :: HTMLElement` that merges `D` into its dataset value.
 */
export type DatasetElement<D extends Record<string, string>, T extends HTMLElement = HTMLElement> = T & {
    dataset: D
};
