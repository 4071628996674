import { addClasses, innerText, queryAll, removeClasses } from 'lambda-dom';
import { isNil } from 'ramda';
import { LocalizedLink, TranslatedElement, TranslatedPlaceholder, TranslateToggle } from './speak-up.types';
import { capitalize } from '../../lib/base.lib';
import { bugsnagClient } from '../../bootstrap/bugsnag.bootstrap';
import * as HistoryService from '../../services/history';

export function deactivateElementOnBodyClick(element: Element, excludedTargetSelector: string): void {
    document.body.addEventListener('click', ({ target }) => {
        if (target instanceof Element && isNil(target.closest(excludedTargetSelector))) {
            removeClasses('active')(element);
        }
    });
}

export function translate(locale: string): void {
    for (const translatedElement of queryAll<TranslatedElement>('[data-speakup-translations]')) {
        try {
            const translations = JSON.parse(translatedElement.dataset.speakupTranslations) || {};
            translatedElement.innerHTML = translations[locale];
        } catch (e) {
            bugsnagClient.notify(e);
        }
    }

    for (const localeButton of queryAll<TranslateToggle>('[data-speakup-localize]')) {
        if (localeButton.dataset.speakupLocalize === locale) {
            addClasses('active')(localeButton);
            queryAll('[data-current-locale="full"]').map(innerText(localeButton.innerText));
            queryAll('[data-current-locale="abbr"]').map(innerText(capitalize(locale)));
        } else {
            removeClasses('active')(localeButton);
        }
    }

    for (const input of queryAll<TranslatedPlaceholder>('[data-placeholder-translations]')) {
        try {
            const translations = JSON.parse(input.dataset.placeholderTranslations) || {};
            input.placeholder = translations[locale];
            input.setAttribute('aria-label', translations[locale]);
        } catch (e) {
            bugsnagClient.notify(e);
        }
    }

    for (const link of queryAll<LocalizedLink>('[data-localized-link]')) {
        link.href = `${link.dataset.url}?l=${locale}`;
    }

    HistoryService.push.patchQueryParams({
        l: locale,
    }, true, { encode: false });
}

export function getSpeakUpUrlForLanguage(language: string): string {
    const urlPartOne = 'https://aalberts.speakup.report/';
    const urlPartThree = '/start/home';

    const languages = {
        'cn': 'zh-Hans',
        'de': 'de-DE',
        'en': 'en-GB',
        'es': 'es',
        'fr': 'fr-FR',
        'it': 'it',
        'nl': 'nl-NL',
        'pl': 'pl',
        'ru': 'ru',
    };

    const languageToUse = languages[language] || languages.en;

    return urlPartOne + languageToUse + urlPartThree;
}

export function hideOrShowReportByPhoneButtonForRussia(hide: boolean = true): void {
    const phoneButton = document.querySelector('[data-speakup-phone]');

    if (! phoneButton || ! (phoneButton instanceof HTMLElement)) return;

    if (hide) {
        phoneButton.style.display = 'none';

        return;
    }

    phoneButton.style.display = 'block';
}

export function hideOrShowRussianLanguageInDropdown(hide: boolean = true): void {
    const russianLocalizeButton = document.querySelector('[data-speakup-localize="ru"]');

    if (! russianLocalizeButton || ! (russianLocalizeButton instanceof HTMLElement)) return;

    if (hide) {
        russianLocalizeButton.style.display = 'none';

        return;
    }

    russianLocalizeButton.style.display = 'block';
}
