import EventDelegation from '@jjwesterkamp/event-delegation';
import { hide, queryAll } from 'lambda-dom';
import { fromEvent } from 'rxjs';
import { ScrollBlockers } from '../lib/scrolling.lib';
import { showIf } from '../lib/dom.lib';
import { DatasetElement } from '../types/common.types';

// ------------------------------------------------------------------------------
//      Element refs
// ------------------------------------------------------------------------------

export const mobileHeader = document.getElementById('mobile-header')!;
export const mobileMenuToggle = document.getElementById('mobile-header-menu-toggle')!;
export const mobileSearchToggle = document.getElementById('mobile-header-search-toggle')!;
export const mobileSearchClose = document.getElementById('mobile-search-close')!;

export const mobileHeaderLogo = mobileHeader.querySelector<HTMLElement>('.mobile-header--logo')!;
export const mobileHeaderShareInfo = mobileHeader.querySelector<HTMLElement>('.mobile-header--share-info')!;

const backdrop = mobileHeader.querySelector('.mobile-header--backdrop')!;
const submenus = queryAll<HTMLElement>('.mobile-header--sub', mobileHeader);

// ------------------------------------------------------------------------------
//      Data / config
// ------------------------------------------------------------------------------

enum MobileHeaderState {
    NAV_ACTIVE = 'nav-active',
    SUB_ACTIVE = 'sub-active',
    SEARCH_ACTIVE = 'search-active',
}

enum Blocker {
    MOBILE_SEARCH = 'mobile-search',
    MOBILE_MENU = 'mobile-menu',
}

// ------------------------------------------------------------------------------
//      Lib
// ------------------------------------------------------------------------------

/**
 *
 */
const menuIsActive = () => mobileHeader.classList.contains(MobileHeaderState.NAV_ACTIVE);

/**
 *
 */
const showSubById = (id: string) => {
    submenus.forEach((menu) => showIf(menu.id === id, menu));
    mobileHeader.classList.add(MobileHeaderState.SUB_ACTIVE);
};

/**
 *
 */
const hideSubMenus = () => submenus.forEach(hide);

/**
 *
 */
const openMenu = () => {
    closeSearch();
    mobileHeader.classList.add(MobileHeaderState.NAV_ACTIVE);
    ScrollBlockers.add(Blocker.MOBILE_MENU);
};

/**
 *
 */
const closeMenu = () => {
    mobileHeader.classList.remove(MobileHeaderState.NAV_ACTIVE, MobileHeaderState.SUB_ACTIVE);
    ScrollBlockers.remove(Blocker.MOBILE_MENU);
};

/**
 *
 */
const openSearch = () => {
    closeMenu();
    mobileHeader.classList.add(MobileHeaderState.SEARCH_ACTIVE);
    ScrollBlockers.add(Blocker.MOBILE_SEARCH);
};

/**
 *
 */
const closeSearch = () => {
    mobileHeader.classList.remove(MobileHeaderState.SEARCH_ACTIVE);
    ScrollBlockers.remove(Blocker.MOBILE_SEARCH);
};

// ------------------------------------------------------------------------------
//      Run the shit
// ------------------------------------------------------------------------------

fromEvent(mobileMenuToggle, 'click').subscribe(() => (menuIsActive()
    ? closeMenu()
    : openMenu()));
fromEvent(backdrop, 'click').subscribe(closeMenu);

fromEvent(mobileSearchToggle, 'click').subscribe(openSearch);
fromEvent(mobileSearchClose, 'click').subscribe(closeSearch);

EventDelegation
    .within(mobileHeader)
    .events('click')
    .select('.mobile-header--sub--return')
    .listen(() => {
        mobileHeader.classList.remove(MobileHeaderState.SUB_ACTIVE);
        setTimeout(hideSubMenus, 200);
    });

EventDelegation
    .within(mobileHeader)
    .events('click')
    .select('.mobile-header--sub--link')
    .listen(() => {
        closeMenu();
    });

type SubmenuToggle = DatasetElement<{ submenuId: string }, HTMLButtonElement>;

EventDelegation
    .within(mobileHeader)
    .events('click')
    .select<SubmenuToggle>('button[data-submenu-id]')
    .listen((e) => showSubById(e.delegator.dataset.submenuId));
