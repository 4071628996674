import Cookies from 'js-cookie';
import { includes, values } from 'ramda';
import { CONSENT_LEVEL_COOKIE_KEY, AvgConsentLevel, cookieExpires } from './cookie-settings.config';

/**
 * @param {number} consentLevel
 *
 * @returns {number}
 */
function getCookieExpiresForConsentLevel(consentLevel: AvgConsentLevel) {

    // Use strict comparison, no switch case:

    if (consentLevel === AvgConsentLevel.ANALYTICS) {
        return cookieExpires.ONE_YEAR;
    }

    if (consentLevel === AvgConsentLevel.NECESSARY) {
        return cookieExpires.ONE_MONTH;
    }

    if (consentLevel === AvgConsentLevel.MARKETING) {
        return cookieExpires.ONE_MONTH;
    }

    // Default for now:
    return cookieExpires.ONE_MONTH;
}

/**
 *
 * @param {number} consentLevel
 * @return {boolean}
 */
export function isValidConsentLevel(consentLevel: AvgConsentLevel) {
    return includes(consentLevel, values(AvgConsentLevel));
}

/**
 *
 * @param {number} consentLevel
 *
 * @return {boolean}
 */
export function setConsentLevel(consentLevel: AvgConsentLevel) {

    if (! isValidConsentLevel(consentLevel)) {
        return false;
    }

    Cookies.set(CONSENT_LEVEL_COOKIE_KEY, consentLevel.toString(), {
        expires: getCookieExpiresForConsentLevel(consentLevel),
    });

    return true;
}

/**
 *
 * @returns {number|undefined}
 */
export function getConsentLevel() {
    const stringValue = Cookies.get(CONSENT_LEVEL_COOKIE_KEY);
    try {
        return stringValue ? JSON.parse(stringValue) : undefined;
    } catch (error) {
        return undefined;
    }
}
