// Must be in sync with the bootstrap config!
export enum Breakpoint {
    XS = 0,
    MIN = 375,
    SM = 576,
    MD = 768,
    LG = 1080,
    XL = 1200,
    XXL = 1400,
    XXXL = 1700,
}
