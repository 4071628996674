import QueryString from 'qs';
import { hide, show } from 'lambda-dom';
import { fromEvent, merge } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { propEq } from 'ramda';
import { visuallyHide, visuallyShow } from '../../lib/dom.lib';
import { ScrollBlockers, scrollToId } from '../../lib/scrolling.lib';

export function videoPlayerOverLayService(
    cityLoopVideo: HTMLVideoElement,
    cityTourVideo: HTMLVideoElement,
    showTourButton: HTMLElement,
    sectionTitle: HTMLElement,
    videoSection: HTMLElement,
    scrollToIdString: string,
    TOUR_SCROLL_BLOCKER: string,
    TOUR_VIDEO_END_CUT: number,
    locationHref: string,
    homepagePositionFix: boolean = false,
) {
    merge(

        fromEvent(window, 'load').pipe(filter((): boolean => {
            const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true }) as any;
            return propEq('tour', '1', query);
        })),

        fromEvent(showTourButton, 'click'),
    )
        .subscribe(async () => {

            await scrollToId(scrollToIdString);

            ScrollBlockers.add(TOUR_SCROLL_BLOCKER);

            visuallyHide(sectionTitle);
            hide(showTourButton);
            hide(cityLoopVideo);
            show(cityTourVideo);

            if (homepagePositionFix) {
                videoSection.classList.add('video-is-playing');
            }

            await cityTourVideo.play();
            await fromEvent(cityTourVideo, 'timeupdate').pipe(
                filter(() => cityTourVideo.currentTime > (cityTourVideo.duration - TOUR_VIDEO_END_CUT)),
                first(),
            ).toPromise();

            ScrollBlockers.remove(TOUR_SCROLL_BLOCKER);

            if (homepagePositionFix) {
                videoSection.classList.remove('video-is-playing');
            }

            visuallyShow(sectionTitle);
            show(showTourButton);
            show(cityLoopVideo);
            hide(cityTourVideo);

            window.location.href = locationHref;
        });
}


