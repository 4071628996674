export enum HeaderTheme {
    WHITE = 'theme-white',
    TRANSPARENT_WHITE = 'theme-transparent-white',
    BLACK = 'theme-black',
}

export type HeaderDisplay =
    | 'share-info'
    | 'brand-logo'
    | 'none';

export enum StateClass {
    ACTIVE = 'active',
    BANNER_ACTIVE = 'banner-active',
    SEARCH_ACTIVE = 'search-active',
    SUB_ACTIVE = 'sub-active',
}
