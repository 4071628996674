import animateScrollTo from 'animated-scroll-to';
import { includes, isNil, not, without } from 'ramda';
import { Subscription } from 'rxjs';
import { hashLinkTriggers$ } from '../bootstrap/dom-events.bootstrap';

// ------------------------------------------------------------------------------
//      Scroll blocking
// ------------------------------------------------------------------------------
export const ScrollBlockers = (() => {
    let names: string[] = [];

    return Object.freeze({

        has(name: string): boolean {
            return includes(name, names);
        },

        /**
         * Adds given scroll-blocker to the list of blockers, if it's not already
         * on it.
         * Returned boolean indicates whether the set transitioned from the empty state
         */
        add(name: string): boolean {
            if (this.has(name)) return false;
            const names0 = names;
            names = [name, ...names];
            this.apply();
            return names0.length === 0;
        },

        /**
         * Adds given scroll-blocker to the list of blockers, if it's not already
         * on it.
         * Returned boolean indicates whether the set transitioned to the empty state
         */
        remove(name: string): boolean {
            if (! this.has(name)) return false;
            const names0 = names;
            names = without([name], names0);
            this.apply();
            return names.length === 0 && names0.length === 0;
        },

        toggle(name: string, force?: boolean): boolean {
            return (force ?? not(this.has(name)))
                ? this.add(name)
                : this.remove(name);
        },

        count(): number {
            return names.length;
        },

        reset(): void {
            names = [];
        },

        isEmpty(): boolean {
            return names.length === 0;
        },

        apply(): void {
            document.body.style.overflow = this.isEmpty() ? '' : 'hidden';
        },
    });
})();

// ------------------------------------------------------------------------------
//      Library
// ------------------------------------------------------------------------------

/**
 * Smooth scroll to the element by given `id`. Returns a promise that resolves `false`
 * if such an element does not exist, or if the scrolling function reports failure.
 * Resolves `true` otherwise.
 */
export const scrollToId = (id: string): Promise<boolean> => {

    const element = document.getElementById(id);

    return isNil(element)
        ? Promise.resolve(false)
        : scrollToElement(element);
};

/**
 * Smooth scroll to a given element. Returns a promise that resolves `false` if
 * the scrolling function could not complete, eg. because of new UI scroll events.
 * Resolves `true` otherwise.
 */
export const scrollToElement = (el: HTMLElement): Promise<boolean> => animateScrollTo(el, {
    maxDuration: 1800,
});

/**
 * Automatically smooth-scroll to ID-qualified same-page elements on activation
 * of links to elements on the current page; links with a #hash-value. Returns an
 * RxJS subscription that can be cancelled to stop the auto-scrolling.
 */
export const hashAutoScroll = (): Subscription => hashLinkTriggers$.subscribe(({ event, link, hashTarget }) => {

    // Prevent the default navigation behavior (a hash change and instant
    // jump to element).
    event.preventDefault();

    // Blur the clicked link. This is done to prevent :focus-within state
    // from keeping the main navigation open. Now the navigation will hide
    // on "mouseleave".
    link.blur();

    scrollToElement(hashTarget);

    // Update the current history state (with URL) to match the default
    // browser behavior: set the hash value of the current url to the id
    // of the target element.
    history.replaceState(
        history.state,
        document.title,
        `${location.origin + location.pathname + location.search}#${hashTarget.id}`,
    );
});
