import QueryString, { IStringifyOptions, ParsedQs } from 'qs';
import { mergeDeepLeft, mergeDeepRight } from 'ramda';

// ------------------------------------------------------------------------------
//      Current state
// ------------------------------------------------------------------------------

export const current = {
    state: () => window.history.state,
    title: () => String(document.title),
    url: () => window.location.href,
    path: () => window.location.pathname,
    protocol: () => window.location.protocol,
    queryObject: <T extends ParsedQs>() => QueryString.parse(window.location.search, { ignoreQueryPrefix: true }) as T,
};

// ------------------------------------------------------------------------------
//      Update history
// ------------------------------------------------------------------------------

interface HistoryData {
    data: any;
    title: string;
    url: string;
}

const historyAction = (executor: (settings: HistoryData) => void) => ({

    setQueryParams(params: any = {}, opts: IStringifyOptions = {}) {
        this.run({ url: current.path() + QueryString.stringify(params, { ...opts, addQueryPrefix: true }) });
    },

    patchQueryParams(params: any = {}, overrideExisting: boolean = true, opts: IStringifyOptions = {}) {
        const currentParams = current.queryObject();
        const newParams = overrideExisting
            ? mergeDeepRight(currentParams, params)
            : mergeDeepLeft(currentParams, params);

        this.run({ url: current.path() + QueryString.stringify(newParams, { ...opts, addQueryPrefix: true }) });
    },

    run({ data = current.state(), title = current.title(), url = current.url() }: Partial<HistoryData>): void {
        executor({ data, title, url });
    },
});

export const push = historyAction(({ data, title, url }) => window.history.pushState(data, title, url));
export const replace = historyAction(({ data, title, url }) => window.history.replaceState(data, title, url));
