// ------------------------------------------------------------------------------
//      Elements
// ------------------------------------------------------------------------------

export const video = document.getElementById('hero-video')!;
export const videoContainer = document.getElementById('hero-video-container')!;

// ------------------------------------------------------------------------------
//      config
// ------------------------------------------------------------------------------

export const videoNativeWidth = 1920;
export const videoNativeHeight = 1080;

// ------------------------------------------------------------------------------
//      Lib
// ------------------------------------------------------------------------------

export const sizeHeroVideo = () => {

    const parentHeight = videoContainer.offsetHeight;
    const parentWidth = videoContainer.offsetWidth;

    // Get the scale factors
    const heightScaleFactor = parentHeight / videoNativeHeight;
    const widthScaleFactor = parentWidth / videoNativeWidth;

    // Based on highest scale factor set width and height
    if (widthScaleFactor > heightScaleFactor) {
        video.style.height = 'auto';
        video.style.width = `${parentWidth}px`;
    } else {
        video.style.height = `${parentHeight}px`;
        video.style.width = 'auto';
    }
};

export const sizeHeroVideoSus = () => {

    const parentHeight = videoContainer.offsetHeight;
    const parentWidth = videoContainer.offsetWidth;

    // Get the scale factors
    const heightScaleFactor = parentHeight / videoNativeHeight;
    const widthScaleFactor = parentWidth / videoNativeWidth;

    // Based on highest scale factor set width and height
    if (widthScaleFactor > heightScaleFactor) {
        video.style.height = '100%';
        video.style.width = `${parentWidth}px`;
    } else {
        video.style.height = `${parentHeight}px`;
        video.style.width = 'auto';
    }
};