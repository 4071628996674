export enum AvgConsentLevel {
    NECESSARY = 1,
    ANALYTICS = 2,
    MARKETING = 3,
}

export const CONSENT_LEVEL_COOKIE_KEY = 'AVG-GDPR--consent-level';

export const cookieExpires = {
    ONE_YEAR: 365,
    TWO_YEARS: 2 * 365,
    ONE_MONTH: 30,
};
