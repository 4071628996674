import axios from 'axios';
import { getMeta } from 'lambda-dom';

const CSRFToken = getMeta('csrf-token');

Object.assign(axios.defaults.headers, {
    'X-CSRF-TOKEN': CSRFToken,
    'X-Requested-With': 'XMLHttpRequest',
});

export { axios, CSRFToken };
