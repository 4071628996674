import { head, mergeDeepRight, mergeLeft, toPairs } from 'ramda';
import { hide, show } from 'lambda-dom';
import { AjaxFormConfig, AjaxFormMethod } from './ajax-form.types';
import { CSRFToken } from '../../bootstrap/axios.bootstrap';
import { inputName } from './ajax-form.lib';
import { closestWithin } from '../../lib/dom.lib';

/**
 * AjaxForm configuration factory.
 */
export function createConfig(
    action: string,
    method: AjaxFormMethod,
    overrides: Partial<AjaxFormConfig> = {},
): AjaxFormConfig {
    return mergeLeft<Partial<AjaxFormConfig>, AjaxFormConfig>(overrides, {
        action,
        method,

        requestHeaders: {
            'X-CSRF-TOKEN': CSRFToken!,
            'X-Requested-With': 'XMLHttpRequest',
        },

        inputElementSelector(name) {
            return `[name="${name}"]`;
        },

        errorElementSelector(name) {
            return name
                ? `.input-wrapper--error--content[data-for="${name}"], .group-error[data-for="${name}"]`
                : '.input-wrapper--error--content, .group-error';
        },

        transformErrorResponse(errorResponseBody) {
            return toPairs<string[]>(errorResponseBody.errors).reduce((transformed, [namespace, messages]) => (
                mergeDeepRight(transformed, { [inputName(namespace)]: head(messages) })
            ), {});
        },

        insertError(element, message) {
            element.innerText = message;
            const errorWrapper = closestWithin(element, '.input-wrapper--error', document.body);
            if (errorWrapper) {
                show(errorWrapper);
            }
        },

        clearError(element) {
            element.innerText = '';
            const errorWrapper = closestWithin(element, '.input-wrapper--error', document.body);
            if (errorWrapper) {
                hide(errorWrapper);
            }
        },
    });
}
