import { isNil, not } from 'ramda';
import { display, hide } from 'lambda-dom';

export function visuallyHide(element: HTMLElement | null): void {
    if (isNil(element)) {
        return console.warn('Cannot hide null or undefined Element reference');
    }
    element.style.visibility = 'hidden';
}

// noinspection JSUnusedGlobalSymbols
export function visuallyShow(element: HTMLElement | null): void {
    if (isNil(element)) {
        return console.warn('Cannot hide null or undefined Element reference');
    }
    element.style.visibility = 'visible';
}

/**
 * Shows given element if `cond` is truthy. Otherwise given element is being hidden.
 */
export function showIf(cond: boolean, element: HTMLElement, displayValue: string | null = null): void {
    if (cond) {
        display(displayValue)(element);
    } else {
        hide(element);
    }
}

// ------------------------------------------------------------------------------
//      Element class helpers
// ------------------------------------------------------------------------------

/**
 * Adds given className(s) to given element if `cond` is truthy. Otherwise the classes are removed from given
 * element's classList. Takes either a string or array of strings for a single and multiple classes respectively.
 */
export function haveClassIf(cond: boolean, element: HTMLElement, classes: string | string[]): void {

    const classArray = Array.isArray(classes) ? classes : [classes];

    if (cond) {
        element.classList.add(...classArray);
    } else {
        element.classList.remove(...classArray);
    }
}

export function haveClassUnless(cond: boolean, element: HTMLElement, classes: string | string[]): void {
    haveClassIf(not(cond), element, classes);
}

/**
 * An interface extension for `Element` based on the
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/matches MDN `matches` polyfill}
 * used for cross-browser compatible CSS selector matching.
 */
interface XBrowserElement extends Element {
    document?: Document
    matchesSelector?(selectors: string): boolean
    mozMatchesSelector?(selectors: string): boolean
    msMatchesSelector?(selectors: string): boolean
    oMatchesSelector?(selectors: string): boolean
}

export function isFunction(subject: any): subject is Function {
    return typeof subject === 'function';
}

/**
 * Tells whether given element matches a given CSS selector.
 *
 * @param element
 * @param selector
 */
export function matches(element: XBrowserElement, selector: string): boolean {
    if (! (element instanceof Element)) {
        throw new Error('cannot match a non-element against a selector');
    }

    if (isFunction(element.matches)) return element.matches(selector);
    if (isFunction(element.matchesSelector)) return element.matchesSelector(selector);
    if (isFunction(element.mozMatchesSelector)) return element.mozMatchesSelector(selector);
    if (isFunction(element.msMatchesSelector)) return element.msMatchesSelector(selector);
    if (isFunction(element.oMatchesSelector)) return element.oMatchesSelector(selector);
    if (isFunction(element.webkitMatchesSelector)) return element.webkitMatchesSelector(selector);

    {
        const matchingElements = (element.document || element.ownerDocument).querySelectorAll(selector);
        let i = matchingElements.length;

        // eslint-disable-next-line no-empty
        while (--i >= 0 && matchingElements.item(i) !== element) {
        }

        return i > -1;
    }
}

/**
 * Queries up the DOM for given `selector`, starting from given `leafElement`.
 * The first element found matching `selector` will be returned.
 * Querying will stop as soon as given `root` is encountered.
 * If no matching element was found, `null` is returned.
 *
 * Based on the {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill MDN `closest` polyfill}
 *
 * @param leaf     The innermost element in the DOM tree to start searching from.
 * @param selector The selector (CSS style) to match ancestor elements with.
 * @param root     The element that acts as a scope for the query.
 */
export function closestWithin(leaf: HTMLElement, selector: string, root: Node): HTMLElement | null {

    if (isFunction(leaf.closest)) {
        const closest = leaf.closest<HTMLElement>(selector);

        if (closest === null || ! root.contains(closest)) {
            return null;
        }

        return closest;
    }

    if (! root.contains(leaf)) {
        return null;
    }

    let current: HTMLElement | null = leaf;

    do {
        if (matches(current, selector)) {
            return current;
        }

        current = current.parentElement;
    } while (
        current !== null
        && current !== root
        && current.nodeType === Node.ELEMENT_NODE
    );

    return null;
}
